<template>
  <section>
    <!-- Loading -->

    <template>
      <!-- Loading -->
      <div v-if="!ordersLoaded" class="loading apollo">Loading...</div>

      <!-- Error -->
      <div v-else-if="orders && orders.length > 0">
        <v-card-title> Orders </v-card-title>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-checkbox
            class="filter-checkbox mr-4"
            value
            v-model="onlyMyOrders"
            v-if="isAdmin || isClientAdmin || isClientUser"
            label="Only My Orders"
          ></v-checkbox>
          <v-select
            class="filter-input mr-2"
            single-line
            v-if="isAdmin || isClientUser || isClientAdmin || isDriver"
            hide-details
            :items="
              [
                {
                  text: 'All Orders',
                  value: null,
                },
              ].concat(
                orderTypes.map((item) => {
                  return {
                    text: item.name,
                    value: parseInt(item.value),
                  };
                })
              )
            "
            v-model="orderTypeSelected"
            label="All Orders"
          ></v-select>
          <v-select
            class="filter-input mr-2"
            single-line
            v-if="isAdmin"
            hide-details
            :items="
              !customers
                ? [
                    {
                      text: 'All Clients',
                      value: null,
                    },
                  ]
                : [
                    {
                      text: 'All Clients',
                      value: null,
                    },
                  ].concat(
                    customers.map((item) => {
                      return {
                        text: item.name,
                        value: parseInt(item.id),
                      };
                    })
                  )
            "
            v-model="customer"
            label="All Clients"
          ></v-select>

          <v-select
            class="filter-input mr-2"
            single-line
            hide-details
            :items="
              [
                {
                  text: 'All Passengers',
                  value: null,
                },
              ].concat(
                patients
                  .filter((item) => {
                    if (item !== null) {
                      if (customer) {
                        if (item.customer.id == customer) {
                          return true;
                        } else {
                          return false;
                        }
                      } else {
                        return true;
                      }
                    }
                  })
                  .map((item) => {
                    return {
                      text: item.firstName + ' ' + item.lastName,
                      value: parseInt(item.id),
                    };
                  })
              )
            "
            v-model="patient"
            label="All Passengers"
          ></v-select>

          <div class="d-flex justify-center mr-2">
            <v-select
              v-if="timeframe !== -100"
              class="filter-input"
              single-line
              hide-details
              :items="
                !timeframes
                  ? [
                      {
                        text: 'All Orders',
                        value: null,
                      },
                    ]
                  : [
                      {
                        text: 'All Orders',
                        value: null,
                      },
                    ].concat(timeframes)
              "
              v-model="timeframe"
              label="All Orders"
            ></v-select>
            <div style="width: 160px" v-if="timeframe === -100">
              <v-dialog
                ref="dialogStart"
                v-model="filterDateStartModal"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterDateStart"
                    label="Date From"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    style="width: 150px"
                    class="ml-2"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filterDateStart"
                  @click:date="filterDateStartModal = false"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="filterDateStartModal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogStart.save(filterDateStart)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
            <div style="width: 160px" v-if="timeframe === -100">
              <v-dialog
                ref="dialogEnd"
                v-model="filterDateEndModal"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterDateEnd"
                    label="Date To"
                    prepend-icon="mdi-calendar"
                    readonly
                    style="width: 150px"
                    class="ml-2 mr-2"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filterDateEnd"
                  @click:date="filterDateEndModal = false"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="filterDateEndModal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogEnd.save(filterDateEnd)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
            <v-btn
              v-if="timeframe == -100"
              icon
              @click="timeframe = 1"
              style="mr-2"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-select
            class="filter-input mr-2"
            single-line
            v-if="isAdmin"
            hide-details
            :items="
              !users
                ? [
                    {
                      text: 'All Drivers',
                      value: null,
                    },
                    {
                      text: 'Not Assigned',
                      value: -1,
                    },
                  ]
                : [
                    {
                      text: 'All Drivers',
                      value: null,
                    },
                    {
                      text: 'Not Assigned',
                      value: -1,
                    },
                  ].concat(
                    users.map((item) => {
                      return {
                        text: item.firstName + ' ' + item.lastName,
                        value: parseInt(item.id),
                      };
                    })
                  )
            "
            v-model="driver"
            label="All Drivers"
          ></v-select>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            class="filter-input"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="orders"
          class="elevation-1"
          :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
          :items-per-page="30"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    >Add Order</v-btn
                  >
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <AddEditOrderComponent
                    v-if="dialog && editedItem"
                    :saveCallback="save"
                    :cancelCallback="cancel"
                    :editedItem="editedItem"
                    :setMessageSnackbar="setMessageSnackbar"
                    :key="new Date().getTime()"
                  />
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.id="{ item }">{{ item.id }} </template>
          <template v-slot:item.patient="{ item }">
            {{ item.patient ? item.patient.firstName : "-" }}
            {{ item.patient ? item.patient.lastName : "-" }}
          </template>
          <!-- <template v-slot:item.phone="{ item }">{{
              item.patient ? item.patient.phone : "-"
            }}</template> -->
          <template v-slot:item.pickupLocation="{ item }">
            {{ item.pickupLocation ? item.pickupLocation.name : "-" }}
          </template>
          <template v-slot:item.destination="{ item }">
            {{ item.destination ? item.destination.alias : "-" }}
          </template>

          <template v-slot:item.dateStart="{ item }">
            <span
              v-luxon:date_med="{
                value: item.dateStart + 'T12:00:00',
              }"
            />
          </template>
          <template v-slot:item.timeStart="{ item }">
            {{ tConvert(item.timeStart) }}
          </template>
          <template v-slot:item.dateEnd="{ item }">
            <span
              v-luxon:date_med="{
                value: item.dateEnd + 'T12:00:00',
              }"
            />
          </template>
          <template v-slot:item.repeat="{ item }">
            {{ getRepeatString(item, item.repeat) }}
          </template>
          <template v-slot:item.note="{ item }">{{ item.note }}</template>

          <template v-slot:item.roundTrip="{ item }">
            {{ item.roundTrip == 0 ? "no" : "yes" }}
          </template>
          <!-- <template v-slot:item.confirmed="{ item }">{{ item.confirmed ? 'yes' : 'no' }}</template>
            <template v-slot:item.complete="{ item }">{{ item.complete ? 'yes' : 'no' }}</template>-->
          <template v-slot:item.customer="{ item }">
            {{ item.customer ? item.customer.name : "-" }}
          </template>
          <template v-slot:item.driver="{ item }">
            {{
              item.driver
                ? item.driver.firstName + " " + item.driver.lastName
                : "Not yet assigned"
            }}
            {{
              item.driver && item.driver.phone ? ", " + item.driver.phone : ""
            }}
          </template>
          <template v-slot:item.price="{ item }">
            {{ calculatePrice(item).toFixed(2) }}
            ({{
              !item.sub_orders
                ? 1
                : item.sub_orders.filter((item) => item.status != "Canceled")
                    .length
            }})
          </template>
          <template v-slot:item.priceDriver="{ item }">
            {{ calculateDriverPrice(item).toFixed(2) }}
            ({{
              !item.sub_orders
                ? 1
                : item.sub_orders.filter((item) => item.status != "Canceled")
                    .length
            }})
          </template>
          <template v-slot:item.distance="{ item }"
            >{{ item.distance ? item.distance.toFixed(2) : "0" }} miles
          </template>
          <template v-slot:item.customer="{ item }">
            {{ item.customer ? item.customer.name : "-" }}
          </template>
          <template v-slot:item.ordered_by="{ item }">
            {{
              item.ordered_by
                ? item.ordered_by.firstName + " " + item.ordered_by.lastName
                : "Not defined"
            }}
            <!-- {{
                item.ordered_by && item.ordered_by.role
                  ? ", " + item.ordered_by.role.name
                  : ""
              }} -->
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="infoOrder(item)"
              v-if="item.notifications && item.notifications.length > 0"
              >mdi-information-variant</v-icon
            >

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                  v-if="isAdmin || isClientUser || isClientAdmin"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-pencil</v-icon
                >
              </template>
              <span>Edit Order</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="deleteItem(item)"
                  v-if="isAdmin"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Delete Order</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
          </template>
          <!--  -->
          <template v-slot:expanded-item="{ item, headers }">
            <td :colspan="headers.length">
              <v-simple-table
                class="order-expanded-table"
                style="margin: 20px 0"
              >
                <template v-slot:default>
                  <!-- {{canSeePrice}}
                    {{canSeeDriverPrice}} -->
                  <thead>
                    <tr>
                      <!-- <th>Completed</th> -->
                      <th>Status</th>
                      <th>Date</th>
                      <th>Appointment Time</th>
                      <th>Pickup Time</th>
                      <th>Round Trip</th>
                      <th v-if="canSeePrice">Price</th>
                      <th v-if="canSeeDriverPrice">Price Driver</th>
                      <th>Driver</th>
                      <th>Notes</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        orderItem, orderItemIdx
                      ) in item.sub_orders.filter((item) =>
                        orderTypeSelected == 1 &&
                        (item.status == 'Canceled' ||
                          item.status == 'Completed')
                          ? false
                          : isDriver && user.id != item.driver.id
                          ? false
                          : timeframe != null
                          ? checkOrderTimeFrame(item)
                          : true
                      )"
                      :key="orderItemIdx"
                    >
                      <td>
                        {{ orderItem.status }}
                      </td>
                      <td>
                        <span
                          v-luxon:date_med="{
                            value: orderItem.date + 'T12:00:00',
                          }"
                        />
                      </td>
                      <td>
                        {{ tConvert(orderItem.time) }}
                      </td>
                      <td>
                        {{
                          orderItem.pickupTime
                            ? tConvert(orderItem.pickupTime)
                            : "Not defined"
                        }}
                      </td>
                      <td>
                        {{
                          (
                            orderItem.roundTrip
                              ? orderItem.roundTrip
                              : orderItem.roundTrip
                          )
                            ? "yes"
                            : "no"
                        }}
                      </td>
                      <td v-if="canSeePrice">
                        {{ orderItem.price.toFixed(2) }}
                      </td>
                      <td v-if="canSeeDriverPrice">
                        {{ orderItem.priceDriver.toFixed(2) }}
                      </td>

                      <td>
                        {{
                          orderItem.driver
                            ? orderItem.driver.firstName +
                              " " +
                              orderItem.driver.lastName
                            : "Not yet assigned"
                        }}
                        {{
                          orderItem.driver && orderItem.driver.phone
                            ? ", " + orderItem.driver.phone
                            : ""
                        }}
                      </td>
                      <td>
                        {{ orderItem.note }}
                      </td>

                      <td>
                        <v-icon
                          small
                          class="mr-2"
                          @click="chatSubOrderItem(item, orderItem)"
                          v-if="
                            (isAdmin || isClientUser || isClientAdmin) &&
                            orderItem.driver
                          "
                          >mdi-chat</v-icon
                        >

                        <v-icon
                          small
                          class="mr-2"
                          @click="infoSuborder(item, orderItem)"
                          v-if="
                            orderItem.notifications &&
                            orderItem.notifications.length > 0
                          "
                          >mdi-information-variant</v-icon
                        >

                        <v-icon
                          small
                          class="mr-2"
                          @click="editSubOrderItem(item, orderItem)"
                          v-if="
                            isAdmin || isClientUser || isClientAdmin || isDriver
                          "
                          >mdi-pencil</v-icon
                        >

                        <v-icon
                          small
                          @click="cancelOrderItem(item, orderItem)"
                          v-if="
                            (isAdmin || isClientUser || isClientAdmin) &&
                            cancelOrderEnabled(item, orderItem)
                          "
                          >mdi-close</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
          <!--  -->
        </v-data-table>
      </div>
      <!-- No result -->
      <div v-else class="no-result apollo">No results</div>
    </template>
    <v-dialog v-model="dialogSubOrder" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline"
            >Edit Appointment
            {{ editedSubOrderItem ? editedSubOrderItem.date : "" }}</span
          >
        </v-card-title>

        <AddEditSubOrderComponent
          :cancelCallback="cancelSubOrder"
          v-if="dialogSubOrder && editedSubOrderItem"
          :saveCallback="saveSubOrder"
          :editedItem="editedSubOrderItem"
          :key="new Date().getTime()"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="notificationsModal" max-width="800px">
      <v-card>
        <NotificationsComponent
          :notifications="notificationsList"
          v-if="notificationsModal"
          :notificationListId="notificationsListId + '_' + user.id"
          :key="new Date().getTime()"
        />
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="messageOnScreenModal"
      absolute
      centered
      color="primary"
      elevation="24"
      style="z-index: 20001"
    >
      {{ messageOnScreenText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary pr-5 pl-5"
          dark
          v-bind="attrs"
          @click="messageOnScreenModal = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="dialogCancelOrder" max-width="800px" persistent>
      <v-card>
        <v-card-title style="word-wrap: wrap">
          <span class="headline"> {{ dialogCancelTitle }}</span>
        </v-card-title>
        <div style="max-width: 500px; margin: auto">
          <v-text-field
            v-model="dialogCancelReason"
            hide-details="auto"
            :rules="requiredRules"
            label="Cancellation Reason"
            required
            outlined
            class="mt-4"
          ></v-text-field>
        </div>
        <v-card-actions class="order-popup-actions">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogCancelOrder = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="cancelOrderItemConfirm()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import AddEditOrderComponent from "@/components/data/AddEditOrderComponent";
import AddEditSubOrderComponent from "@/components/data/AddEditSubOrderComponent";
import { DateTime } from "luxon";
import NotificationsComponent from "@/components/data/NotificationsComponent";
import { convertTime12to24 } from "@/utils/datetime.js";

export default {
  name: "AvailableOrdersPage",
  components: {
    AddEditOrderComponent,
    AddEditSubOrderComponent,
    NotificationsComponent,
  },
  data: () => ({
    onlyMyOrders: true,
    //
    notificationsModal: false,
    notificationsList: [],
    notificationsListId: null,
    //
    messageOnScreenModal: false,
    messageOnScreenText: "",
    //
    expanded: [],
    singleExpand: false,
    //
    dialog: false,
    //
    search: "",
    timeframe: 1,
    filterDateStart: null,
    filterDateStartModal: false,
    filterDateEnd: null,
    filterDateEndModal: false,
    //
    customer: null,
    orderTypeSelected: 1,
    driver: null,
    patient: null,
    //
    editedIndex: -1,
    editedItem: {},
    defaultOrder: {
      repeat: -1,
      roundTrip: 1,
      repeatSun: false,
      repeatMon: true,
      repeatTue: true,
      repeatWed: true,
      repeatThu: true,
      repeatFri: true,
      repeatSat: false,
    },
    //
    editedSubOrderItem: {},
    dialogSubOrder: false,
    //
    cachedDataRefference: undefined,
    //
    dialogCancelOrder: false,
    dialogCancelOrderItem: null,
    dialogCancelReason: null,
    dialogCancelTitle: null,
    //
    repeatOptions: [
      {
        text: "None",
        value: -1,
      },
      {
        text: "Every day",
        value: 1,
      },
      {
        text: "Every weekday",
        value: 2,
      },
      {
        text: "Every week",
        value: 3,
      },
      // {
      //   text: "Every month",
      //   value: 4,
      // },
    ],
    //
    timeframes: [
      {
        text: "Custom Range",
        value: -100,
      },
      {
        text: "Last Week",
        value: -1,
      },
      {
        text: "Today",
        value: 0,
      },
      {
        text: "This Week",
        value: 1,
      },
      {
        text: "Next Week",
        value: 4,
      },
      {
        text: "This Month",
        value: 2,
      },
      {
        text: "Next Month",
        value: 3,
      },
    ],
    orderTypes: [
      {
        name: "Only Active Orders",
        value: 1,
      },
    ],
    //
    patients: [],
    orders: [],
    users: [],
    customers: [],
    ordersLoaded: false,
    requiredRules: [(v) => !!v || "Field is required"],
  }),
  apollo: {
    Orders: {
      prefetch: true,
      query: require("@/graphql/OrdersWithNotifcations.gql"),
      fetchPolicy: "cache-and-network",
      pollInterval: 5000,
      update: (data) => data,
      result(data) {
        if (!data.data) return;
        if (this.dialog || this.dialogSubOrder) return;

        try {
          const { parse, stringify } = require("flatted/cjs");
          localStorage.setItem("orders", stringify(data.data.orders));
          localStorage.setItem("customers", stringify(data.data.customers));
        } catch (err) {
          console.log("error with cache", err);
        }

        this.orders = data.data.orders;
        this.users = data.data.users;
        this.customers = data.data.customers;

        if (this.orders) {
          this.patients = [...new Set(this.orders.map((item) => item.patient))];
          if (this.patients) {
            this.patients.sort(function (a, b) {
              if (
                a &&
                b &&
                a.firstName &&
                b.firstName &&
                a.firstName < b.firstName
              ) {
                return -1;
              }
              if (
                a &&
                b &&
                a.firstName &&
                b.firstName &&
                a.firstName > b.firstName
              ) {
                return 1;
              }
              return 0;
            });
          }
        }

        this.ordersLoaded = true;
      },
    },
    Chats: {
      prefetch: true,
      fetchPolicy: "cache-and-network",
      query: require("@/graphql/ChatsList.gql"),
      update: (data) => data,
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Order" : "Edit Order";
    },
    headers() {
      return [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",

          filterable: false,
        },
        {
          text: "Passenger",
          align: "start",
          sortable: false,
          value: "patient",

          filterable: false,
        },
        // { text: "Phone Number", value: "phone", filterable: false },
        {
          text: "Pickup Location",
          sortable: false,
          value: "pickupLocation",
          filterable: false,
        },
        {
          text: "Destination Location",
          sortable: false,
          value: "destination",
          filterable: false,
        },
        { text: "Start Date", value: "dateStart", filterable: false },
        // { text: "Pickup Time", value: "timeStart", filterable: false },
        { text: "End Date", value: "dateEnd", filterable: false },
        { text: "Repeat", value: "repeat", filterable: false },
        // {
        //   text: "Round Trip",
        //   value: "roundTrip",
        //   roles: ["Admin"],
        //   filterable: false,
        // },

        // { text: "Note", value: "note", filterable: false },
        // {
        //   text: "Confirmed",
        //   value: "confirmed",
        //   roles: ["Admin"],
        //   filterable: false,
        // },
        // { text: "Complete", value: "complete", filterable: false },
        // {
        //   text: "Client",
        //   value: "customer",
        //   roles: ["Admin"],
        //   filterable: false,
        // },
        // { text: "Driver", value: "driver", filterable: false },
        {
          text: "Total Price",
          value: "price",
          filterable: false,
          roles: ["Admin", "ClientUser", "ClientAdmin"],
        },
        {
          text: "Total Driver Cost",
          value: "priceDriver",
          filterable: false,
          roles: ["Admin", "Driver"],
        },
        {
          text: "Distance",
          value: "distance",
          filterable: false,
          roles: ["Admin", "ClientAdmin"],
        },
        {
          text: "Client",
          value: "customer",
          filterable: false,
          roles: ["Admin"],
        },
        {
          text: "Ordered By",
          value: "ordered_by",
          filterable: false,
          roles: ["Admin", "ClientAdmin"],
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filter: this.searchFilter,
        },
      ].filter((item) => {
        if (item.value == "price") {
          if (this.isClientUser || this.isClientAdmin) {
            if (!this.user.showPrice) return false;
            if (this.user.customer && !this.user.customer.showPrice)
              return false;
          }
        }
        if (item.value == "priceDriver") {
          if (this.isDriver) {
            if (!this.user.showPrice) return false;
          }
        }
        return !item.roles || item.roles.includes(this.user.role.name);
      });
    },
    ...mapGetters([
      "user",
      "strapi",
      "isAdmin",
      "isClientAdmin",
      "isClientUser",
      "isDriver",
    ]),
    canSeeDriverPrice() {
      if (!this.isAdmin && !this.isDriver) {
        return false;
      }
      if (this.isDriver && !this.user.showPrice) return false;
      return true;
    },
    canSeePrice() {
      if (!this.isAdmin && !this.isClientAdmin && !this.isClientUser) {
        return false;
      }
      if (this.isClientUser || this.isClientAdmin) {
        if (!this.user.showPrice) return false;
        if (this.user.customer && !this.user.customer.showPrice) return false;
      }
      return true;
    },
  },

  watch: {
    dialog(val) {
      val || this.cancel();
    },
    dialogSubOrder(val) {
      val || this.cancelSubOrder();
    },
    timeframe(val) {
      this.filterDateStart = null;
      this.filterDateEnd = null;
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
    this.onlyMyOrders = !this.isAdmin;

    try {
      const { parse, stringify } = require("flatted/cjs");
      let ordersCached = parse(localStorage.getItem("orders"));
      let customersCached = parse(localStorage.getItem("customers"));
      if (ordersCached) {
        this.orders = ordersCached;
        if (this.orders) {
          this.patients = [...new Set(this.orders.map((item) => item.patient))];
          if (this.patients) {
            this.patients.sort(function (a, b) {
              if (
                a &&
                b &&
                a.firstName &&
                b.firstName &&
                a.firstName < b.firstName
              ) {
                return -1;
              }
              if (
                a &&
                b &&
                a.firstName &&
                b.firstName &&
                a.firstName > b.firstName
              ) {
                return 1;
              }
              return 0;
            });
          }
        }
        this.ordersLoaded = true;
      }
      if (customersCached) {
        this.customers = customersCached;
      }
    } catch (err) {
      console.log("error with cache", err);
    }
  },
  methods: {
    setMessageSnackbar(text) {
      this.messageOnScreenModal = true;
      this.messageOnScreenText = text;
    },
    calculatePrice(item) {
      var price = 0;
      for (let i = 0; i < item.sub_orders.length; i++) {
        if (item.sub_orders[i].status != "Canceled")
          price += item.sub_orders[i].price;
      }
      return price;
    },
    calculateDriverPrice(item) {
      var priceDriver = 0;
      for (let i = 0; i < item.sub_orders.length; i++) {
        if (item.sub_orders[i].status != "Canceled")
          priceDriver += item.sub_orders[i].priceDriver;
      }
      return priceDriver;
    },
    infoOrder(item) {
      // console.log('item.notifications', item.notifications);
      this.notificationsList = item.notifications
        ? item.notifications.slice().reverse()
        : [];
      this.notificationsListId = item.id;
      this.notificationsModal = true;
    },
    async chatSubOrderItem(item, orderItem) {
      if (this.lookingForChat) return;
      if (!orderItem.driver) return;

      // console.log(orderItem);
      this.lookingForChat = true;
      var chatFound = undefined;
      var chatsCached = this.Chats && this.Chats.chats ? this.Chats.chats : [];
      for (let i = 0; i < chatsCached.length; i++) {
        if (!chatsCached[i].users) continue;
        var tempChats = chatsCached[i].users
          .map((item) => item.email)
          .join(", ")
          .toLowerCase();
        // console.log("tempChats", tempChats);
        if (
          tempChats.includes(orderItem.driver.email.toLowerCase()) &&
          tempChats.includes(this.user.email.toLowerCase())
        ) {
          chatFound = chatsCached[i];
          // console.log("chatFound");
          break;
        }
      }

      // console.log(chatFound);

      try {
        if (!chatFound) {
          chatFound = await this.strapi.createEntry("chats", {
            users: [this.user.id, parseInt(orderItem.driver.id)],
          });
        }
        this.lookingForChat = false;
      } catch (err) {
        this.lookingForChat = false;
        return;
      }

      this.$router.push({ name: "ChatPage", query: { id: chatFound.id } });
    },
    infoSuborder(item, orderItem) {
      this.notificationsList = orderItem.notifications
        ? orderItem.notifications.slice().reverse()
        : [];
      this.notificationsModal = true;
    },
    editSubOrderItem(item, orderItem) {
      this.editedSubOrderItem = orderItem;
      this.dialogSubOrder = true;
    },
    cancelOrderEnabled(item, orderItem) {
      // var isFutureOrder = false;
      //TODO: finish
      // for (let i = 0; i < item.data.future.length; i++) {
      //   if (item.data.future[i].date == orderItem.date) {
      //     isFutureOrder = true;
      //     break;
      //   }
      // }
      return orderItem.status == "Pending" || orderItem.status == "Confirmed";
    },
    async cancelOrderItemConfirm() {
      this.dialogCancelOrderItem.status = "Canceled";
      this.dialogCancelOrderItem.cancelReason = this.dialogCancelReason;
      this.strapi.updateEntry(
        "sub-orders",
        parseInt(this.dialogCancelOrderItem.id),
        {
          status: "Canceled",
          cancelReason: this.dialogCancelReason,
          id: parseInt(this.dialogCancelOrderItem.id),
        }
      );
      this.dialogCancelOrder = false;
    },
    cancelOrderItem(item, orderItem) {
      if (orderItem.status != "Pending") {
        if (this.isClientAdmin || this.isClientUser) {
          let currentDateTime = new Date(Date.now()).getTime();
          let appointmentString = `${orderItem.date}T${convertTime12to24(
            orderItem.time
          )}:00`;
          let appointmentDate = new Date(appointmentString);
          let appointmentDateMinusDay = new Date(appointmentString);
          appointmentDateMinusDay.setDate(
            appointmentDateMinusDay.getDate() - 1
          );

          if (
            currentDateTime >= appointmentDateMinusDay.getTime() &&
            currentDateTime < appointmentDate.getTime()
          ) {
            this.messageOnScreenModal = true;
            this.messageOnScreenText =
              "You are not allowed to cancel today’s confirmed appointment, please contact company representative.";

            return;
          }
        }
      }

      this.dialogCancelOrder = true;
      this.dialogCancelOrderItem = orderItem;
      this.dialogCancelReason = "";
      this.dialogCancelTitle =
        orderItem.status == "Confirmed"
          ? "Are you sure you want to cancel already confirmed appointment?"
          : "Are you sure you want to cancel this appointment?";

      //  var result = confirm(
      //     orderItem.status == "Confirmed"
      //       ? "Are you sure you want to cancel already confirmed order?"
      //       : "Are you sure you want to delete this item?"
      //   );
      //   if (result) {
      // orderItem.status = "Canceled";
      // this.strapi.updateEntry("sub-orders", parseInt(orderItem.id), {
      //   status: "Canceled",
      //   id: parseInt(orderItem.id),
      // });
      // }
      // const index = item.data.future.findIndex((i) => orderItem.date == i.date);
      // var result = confirm("Are you sure you want to delete this item?");
      // if (result) {
      //   orderItem.status = "Canceled";
      //   item.data.future.splice(index, 1);
      //   item.data.actual.push(orderItem);
      //   console.log(item.data);
      //   this.strapi.updateEntry(
      //     "orders",
      //     { id: parseInt(item.id) },
      //     { data: item.data, id: parseInt(item.id) }
      //   );
      // }
    },
    getRepeatString(event, id) {
      for (let i = 0; i < this.repeatOptions.length; i++) {
        if (this.repeatOptions[i].value == id) {
          if (id == 3) {
            var weekDays = [];
            if (event.repeatSun) weekDays.push("Sun");
            if (event.repeatMon) weekDays.push("Mon");
            if (event.repeatTue) weekDays.push("Tue");
            if (event.repeatWed) weekDays.push("Wed");
            if (event.repeatThu) weekDays.push("Thu");
            if (event.repeatFri) weekDays.push("Fri");
            if (event.repeatSat) weekDays.push("Sat");
            return (
              this.repeatOptions[i].text + " (" + weekDays.join(", ") + ")"
            );
          }
          return this.repeatOptions[i].text;
        }
      }
    },
    getPatients(resultObject) {
      if (!resultObject) return [];

      let result = resultObject.map((item) => item.patient);

      result.sort(function (a, b) {
        if (a.firstName < b.firstName) {
          return -1;
        }
        if (a.firstName > b.firstName) {
          return 1;
        }
        return 0;
      });

      return result;
    },
    tConvert(time) {
      if (!time) return "Time not selected";
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },

    checkOrderTimeFrame(item) {
      // console.log("checkOrderTimeFrame", item);
      // if (this.timeframe == -1) {
      //   return true;
      // }

      var today = DateTime.local();
      today = DateTime.local(today.year, today.month, today.day);

      switch (this.timeframe) {
        case -100: //Custom Range
          if (this.filterDateStart && this.filterDateEnd) {
            if (new Date(this.filterDateStart) > new Date(this.filterDateEnd)) {
              let temp = this.filterDateStart;
              this.filterDateStart = this.filterDateEnd;
              this.filterDateEnd = temp;
            }
            var iterator = this.timeStringToDateTime(this.filterDateStart);
            var endDate = this.timeStringToDateTime(this.filterDateEnd);

            while (iterator.diff(endDate, "days").toObject().days <= 0) {
              var iteratorISO = iterator.toISODate();
              if (item.date == iteratorISO) return true;
              iterator = iterator.plus({ days: 1 });
            }
          }
          break;
        case -1: //Last Week
          // var endDate = today.plus({ days: 6 - today.weekday + 7 });
          var iterator = today.plus({
            days: (today.weekday == 7 ? 0 : -1 - today.weekday) - 7,
          });
          var endDate = today.plus({
            days: (today.weekday == 7 ? 6 : 6 - today.weekday) - 7,
          });
          // console.log("iterator", iterator, "endDate", endDate);
          while (iterator.diff(endDate, "days").toObject().days <= 0) {
            var iteratorISO = iterator.toISODate();
            if (item.date == iteratorISO) return true;
            iterator = iterator.plus({ days: 1 });
          }
          break;
        case 0: //Today
          var todayISO = today.toISODate();
          if (item.date == todayISO) return true;
          break;
        case 1: //This Week
          var iterator = today.plus({
            days: today.weekday == 7 ? 0 : -1 - today.weekday,
          });
          var endDate = today.plus({
            days: today.weekday == 7 ? 6 : 6 - today.weekday,
          });
          while (iterator.diff(endDate, "days").toObject().days <= 0) {
            var iteratorISO = iterator.toISODate();
            if (item.date == iteratorISO) return true;
            iterator = iterator.plus({ days: 1 });
          }
          break;
        case 2: //This Month
          var iterator = today.startOf("month");
          var endDate = today.endOf("month");
          while (iterator.diff(endDate, "days").toObject().days <= 0) {
            var iteratorISO = iterator.toISODate();
            if (item.date == iteratorISO) return true;
            iterator = iterator.plus({ days: 1 });
          }
          break;
        case 3: //Next Month
          var iterator = today
            .startOf("month")
            .plus({ months: 1 })
            .startOf("month");
          var endDate = today
            .startOf("month")
            .plus({ months: 1 })
            .endOf("month");
          while (iterator.diff(endDate, "days").toObject().days <= 0) {
            var iteratorISO = iterator.toISODate();
            if (item.date == iteratorISO) return true;
            iterator = iterator.plus({ days: 1 });
          }
          break;
        case 4: //Next Week
          // var iterator = today.plus({ days: -1 - today.weekday + 7 });
          // var endDate = today.plus({ days: 6 - today.weekday + 7 });
          var iterator = today.plus({
            days: (today.weekday == 7 ? 0 : -1 - today.weekday) + 7,
          });
          var endDate = today.plus({
            days: (today.weekday == 7 ? 6 : 6 - today.weekday) + 7,
          });
          while (iterator.diff(endDate, "days").toObject().days <= 0) {
            var iteratorISO = iterator.toISODate();
            if (item.date == iteratorISO) return true;
            iterator = iterator.plus({ days: 1 });
          }
          break;
      }
      return false;
    },
    timeStringToDateTime(timeString) {
      var cFormat = "{y}-{m}-{d}";
      var vals = timeString.split("-");
      var formatSplit = cFormat.split("-");
      var m = formatSplit.indexOf("{m}");
      var d = formatSplit.indexOf("{d}");
      var y = formatSplit.indexOf("{y}");
      // console.log("month", vals[m]);
      // console.log("day", vals[d]);
      // console.log("year", vals[y]);

      return DateTime.local(
        parseInt(vals[y]),
        parseInt(vals[m]),
        parseInt(vals[d]),
        0,
        0,
        0
      );
    },
    searchFilter(_value, _search, item) {
      if (this.patient) {
        if (!item.patient || parseInt(item.patient.id) != this.patient)
          return false;
      }

      if (this.checkSubOrdersExist(item) <= 0) {
        return false;
      }

      if (this.onlyMyOrders) {
        if (item.ordered_by && item.ordered_by.id != this.user.id) {
          return false;
        }
      }

      if (this.orderTypeSelected == 1) {
        var foundActive = false;
        for (let i = 0; i < item.sub_orders.length; i++) {
          if (
            item.sub_orders[i].status != "Canceled" &&
            item.sub_orders[i].status != "Completed"
          ) {
            foundActive = true;
            break;
          }
        }
        if (!foundActive) return false;
      }

      var driverFound = false;
      if (this.driver) {
        if (this.driver == -1) {
          //not assigned
          for (let i = 0; i < item.sub_orders.length; i++) {
            if (!item.sub_orders[i].driver) {
              driverFound = true;
              break;
            }
          }
        } else {
          //real driver
          for (let i = 0; i < item.sub_orders.length; i++) {
            if (!item.sub_orders[i].driver) continue;
            if (item.sub_orders[i].driver.id == this.driver) {
              driverFound = true;
              break;
            }
          }
        }
        if (!driverFound) return false;
        // if (this.driver == -1) {
        //   if (item.driver) return false;
        // } else {
        //   if (!item.driver || parseInt(item.driver.id) != this.driver)
        //     return false;
        // }
      }

      if (this.customer) {
        if (!item.customer || parseInt(item.customer.id) != this.customer)
          return false;
      }

      var searchWord = this.search.toLowerCase();
      // console.log(searchWord);
      if (searchWord && searchWord.trim().length > 0) {
        if (
          (item.patient &&
            ((item.patient.firstName &&
              item.patient.firstName.toLowerCase().includes(searchWord)) ||
              (item.patient.lastName &&
                item.patient.lastName.toLowerCase().includes(searchWord)))) ||
          (item.patient &&
            `${item.patient.firstName && item.patient.firstName} ${
              item.patient.lastName && item.patient.lastName
            }`
              .toLowerCase()
              .includes(searchWord))
          //  ||
          //  (item.patient.phone &&
          // item.patient.phone.toLowerCase().includes(searchWord))
        )
          return true;

        if (item.note && item.note.toLowerCase().includes(searchWord))
          return true;
        if ((item.id + "").includes(searchWord)) return true;
        if (
          (item.driver &&
            ((item.driver.firstName &&
              item.driver.firstName.toLowerCase().includes(searchWord)) ||
              (item.driver.lastName &&
                item.driver.lastName.toLowerCase().includes(searchWord)))) ||
          (item.driver &&
            `${item.driver.firstName && item.driver.firstName} ${
              item.driver.lastName && item.driver.lastName
            }`
              .toLowerCase()
              .includes(searchWord))
          //   ||
          // (item.driver.phone &&
          //   item.driver.phone.toLowerCase().includes(searchWord))
        ) {
          return true;
        }
        return false;
      }

      if (
        this.timeframe != null &&
        item.sub_orders &&
        Array.isArray(item.sub_orders)
      ) {
        // console.log("this.timeframe", this.timeframe);
        // var ordersDetailed = item.data.actual.concat(item.data.future);
        var ordersDetailed = item.sub_orders;

        var today = DateTime.local();
        today = DateTime.local(today.year, today.month, today.day);

        switch (this.timeframe) {
          case -100: //Custom Range
            if (this.filterDateStart && this.filterDateEnd) {
              if (
                new Date(this.filterDateStart) > new Date(this.filterDateEnd)
              ) {
                let temp = this.filterDateStart;
                this.filterDateStart = this.filterDateEnd;
                this.filterDateEnd = temp;
              }
              var iterator = this.timeStringToDateTime(this.filterDateStart);
              var endDate = this.timeStringToDateTime(this.filterDateEnd);

              while (iterator.diff(endDate, "days").toObject().days <= 0) {
                var iteratorISO = iterator.toISODate();
                for (let i = 0; i < ordersDetailed.length; i++) {
                  if (ordersDetailed[i].date == iteratorISO) return true;
                }
                iterator = iterator.plus({ days: 1 });
              }
            }
            break;
          case -1: //Last Week
            // var endDate = today.plus({ days: 6 - today.weekday + 7 });
            var iterator = today.plus({
              days: (today.weekday == 7 ? 0 : -1 - today.weekday) - 7,
            });
            var endDate = today.plus({
              days: (today.weekday == 7 ? 6 : 6 - today.weekday) - 7,
            });
            // console.log("start", iterator);
            // console.log("end", endDate);
            while (iterator.diff(endDate, "days").toObject().days <= 0) {
              var iteratorISO = iterator.toISODate();
              for (let i = 0; i < ordersDetailed.length; i++) {
                if (ordersDetailed[i].date == iteratorISO) return true;
              }
              iterator = iterator.plus({ days: 1 });
            }
            break;
          case 0: //Today
            var todayISO = today.toISODate();
            // console.log('todayISO', todayISO, ordersDetailed);
            for (let i = 0; i < ordersDetailed.length; i++) {
              if (ordersDetailed[i].date == todayISO) return true;
            }
            break;
          case 1: //This Week
            // console.log(today.weekday);
            // var iterator = today.plus({ days: -1 - today.weekday });
            // var endDate = today.plus({ days: 6 - today.weekday });
            var iterator = today.plus({
              days: today.weekday == 7 ? 0 : -1 - today.weekday,
            });
            var endDate = today.plus({
              days: today.weekday == 7 ? 6 : 6 - today.weekday,
            });

            // console.log("start", iterator);
            // console.log("end", endDate);
            while (iterator.diff(endDate, "days").toObject().days <= 0) {
              var iteratorISO = iterator.toISODate();
              for (let i = 0; i < ordersDetailed.length; i++) {
                if (ordersDetailed[i].date == iteratorISO) return true;
              }
              iterator = iterator.plus({ days: 1 });
            }
            break;
          case 2: //This Month
            var iterator = today.startOf("month");
            var endDate = today.endOf("month");
            // console.log("start", iterator);
            // console.log("end", endDate);
            while (iterator.diff(endDate, "days").toObject().days <= 0) {
              var iteratorISO = iterator.toISODate();
              for (let i = 0; i < ordersDetailed.length; i++) {
                if (ordersDetailed[i].date == iteratorISO) return true;
              }
              iterator = iterator.plus({ days: 1 });
            }
            break;
          case 3: //Next Month
            var iterator = today
              .startOf("month")
              .plus({ months: 1 })
              .startOf("month");
            var endDate = today
              .startOf("month")
              .plus({ months: 1 })
              .endOf("month");
            // console.log("start", iterator);
            // console.log("end", endDate);
            while (iterator.diff(endDate, "days").toObject().days <= 0) {
              var iteratorISO = iterator.toISODate();
              for (let i = 0; i < ordersDetailed.length; i++) {
                if (ordersDetailed[i].date == iteratorISO) return true;
              }
              iterator = iterator.plus({ days: 1 });
            }
            break;
          case 4: //Next Week
            // var iterator = today.plus({ days: -1 - today.weekday + 7 });
            // var endDate = today.plus({ days: 6 - today.weekday + 7 });
            var iterator = today.plus({
              days: (today.weekday == 7 ? 0 : -1 - today.weekday) + 7,
            });
            var endDate = today.plus({
              days: (today.weekday == 7 ? 6 : 6 - today.weekday) + 7,
            });
            // console.log("start", iterator);
            // console.log("end", endDate);
            while (iterator.diff(endDate, "days").toObject().days <= 0) {
              var iteratorISO = iterator.toISODate();
              for (let i = 0; i < ordersDetailed.length; i++) {
                if (ordersDetailed[i].date == iteratorISO) return true;
              }
              iterator = iterator.plus({ days: 1 });
            }
            break;
        }

        return false;
      }

      return true;
    },

    // getRepeatString(id) {
    //   for (let i = 0; i < this.repeatOptions.length; i++) {
    //     if (this.repeatOptions[i].value == id) {
    //       return this.repeatOptions[i].text;
    //     }
    //   }
    // },
    initialize() {
      // Object.assign(this.editedItem, this.defaultItem);
      this.editedItem = this.lodash.cloneDeep(this.defaultOrder);
    },

    editItem(item) {
      // console.log(item);
      this.editedIndex = this.orders.findIndex((i) => i.id == item.id);
      this.editedItem = this.lodash.cloneDeep(item);
      this.editedItem.customer = item.customer
        ? parseInt(item.customer.id)
        : undefined;
      this.editedItem.patient = item.patient
        ? parseInt(item.patient.id)
        : undefined;
      this.editedItem.driver = item.driver
        ? parseInt(item.driver.id)
        : undefined;
      // console.log(this.editedItem);

      this.dialog = true;
      // this.$nextTick(() => {
      //   this.$refs.googleSearch.textValue = item.address;
      // });
    },

    async deleteItem(item) {
      // console.log(data.orders);
      const index = this.orders.findIndex((i) => item.id == i.id);
      var result = confirm(
        "Are you sure you want to delete order and all appointments inside it?"
      );
      if (result) {
        await this.strapi.deleteEntry("orders", item.id);
        this.orders.splice(index, 1);
      }
    },
    cancelSubOrder() {
      this.dialogSubOrder = false;
    },
    cancel() {
      this.dialog = false;
      this.$nextTick(() => {
        // Object.assign(this.editedItem, this.defaultItem);
        this.editedItem = null;
        this.editedIndex = -1;
        this.editedItem = this.lodash.cloneDeep(this.defaultOrder);
      });
    },
    async saveSubOrder() {
      //
      this.cancelSubOrder();
      this.$apollo.queries.Orders.refetch();
    },
    async save(order, type, processDates) {
      this.cancel();
      try {
        if (type === 'add') {
          var newItem = await this.strapi.createEntry("orders", order);
          processDates(newItem)
        } else {
            var updatedItem = await this.strapi.updateEntry(
              "orders",
              parseInt(order.id),
              order
            );

            processDates(updatedItem);
        }

      } catch(e) {
        console.log(e);
      } finally {
        await this.$apollo.queries.Orders.refetch();
      }
      // await this.$apollo.queries.Orders.refetch();
    },
    // async saveOrder(type, order) {
    //   try {
    //     if (type === 'add') {
    //       var newItem = await this.strapi.createEntry("orders", order);
    //       this.processDates(newItem)
    //     } else {
    //         var updatedItem = await this.strapi.updateEntry(
    //           "orders",
    //           parseInt(order.id),
    //           order
    //         );

    //         this.processDates(updatedItem);
    //     }

    //   } catch(e) {
    //     console.log(e);
    //   } finally {
    //     await this.$apollo.queries.Orders.refetch();
    //   }
    // },
    checkSubOrdersExist(item) {
      return item.sub_orders.filter((item) =>
        this.orderTypeSelected == 1 &&
        (item.status == "Canceled" || item.status == "Completed")
          ? false
          : this.isDriver && user.id != item.driver.id
          ? false
          : this.timeframe != null
          ? this.checkOrderTimeFrame(item)
          : true
      ).length;
    },
    async processDates(order) {
      // console.log("processDates", order);
      // console.log("this.order.data", this.order.data);
      // console.log("this.order.dateStart", this.order.dateStart);

      // var data = this.order.data ? this.order.data : { actual: [], future: [] };
      // data.future = [];

      var data = order.sub_orders ? order.sub_orders : [];
      // console.log("data", data);

      // var actualDates = data.actual.map((item) => item.date);
      var actualDates = data.filter(
        (item) => item.edited || !item.status || item.status != "Pending"
      ); //.map((item) => item.date);

      // console.log("actualDates", actualDates);

      if (!order.dateStart || !order.repeat) {
        console.error("no dateStart or repeat optio");
        return;
      }

      // console.log(
      //   new Date().toISOString(),
      //   new Date().toISOString().substring(0, 10)
      // );

      // }

      // console.log(
      //   data.future.map((item) => {
      //     return { status: "Pending", date: item };
      //   })
      // );
      var newDates = this.prepareDates(actualDates, order);
      // await this.strapi.createEntry("subOrders", this.order);

      newDates = newDates.map((item) => {
        return {
          status: "Pending",
          date: item,
          driver: order.driver ? order.driver.id : undefined,
          time: order.timeStart,
          roundTrip: order.roundTrip,
          order: order.id,
          note: order.note,
          price: order.price,
          priceDriver: order.priceDriver,
        };
      });

      for (let i = 0; i < data.length; i++) {
        if (!actualDates.some((item) => item.id == data[i].id)) {
          this.strapi.deleteEntry("sub-orders", data[i].id);
        }
      }

      order.sub_orders = actualDates;
      for (let i = 0; i < newDates.length; i++) {
        var newSubOrder = await this.strapi.createEntry(
          "sub-orders",
          newDates[i]
        );
        order.sub_orders.push(newSubOrder);
      }

      // console.log("newDates", newDates);

      order.sub_orders = order.sub_orders.map((item) => parseInt(item.id));
      await this.strapi.updateEntry("orders", parseInt(order.id), order);
      //
    },
    prepareDates(actualDates, order) {
      var newDates = [];
      var today = DateTime.local();
      today = DateTime.local(today.year, today.month, today.day);
      // console.log(order.dateStart, order.dateEnd);
      var startDate = this.timeStringToDateTime(order.dateStart);
      var endDate = this.timeStringToDateTime(order.dateEnd);
      // console.log("today Luxon", today, today.toISODate());
      // console.log("startDate Luxon", startDate, startDate.toISODate());
      // console.log("endDate Luxon", endDate, endDate.toISODate());

      var iterator = this.timeStringToDateTime(order.dateStart);

      // console.log("diff", today.diff(startDate, "days").toObject().days);

      // if (today.diff(startDate, "days").toObject().days > 0) data.future = [];
      // else {
      // console.log("order.repeat", order.repeat);
      switch (order.repeat) {
        case -1: //None
          // console.log(today.diff(startDate, "days").toObject().days);
          if (
            !actualDates.some((item) => item.date == startDate.toISODate()) &&
            today.diff(startDate, "days").toObject().days <= 0
          )
            // data.future
            newDates = [startDate.toISODate()];
          break;
        case 1: //Every day
          while (iterator.diff(endDate, "days").toObject().days <= 0) {
            if (
              !actualDates.some((item) => item.date == iterator.toISODate()) &&
              today.diff(iterator, "days").toObject().days <= 0
            )
              // data.future.
              newDates.push(iterator.toISODate());
            iterator = iterator.plus({ days: 1 });
          }
          break;
        case 2: //Every weekday
          while (iterator.diff(endDate, "days").toObject().days <= 0) {
            if (
              iterator.weekday < 6 &&
              !actualDates.some((item) => item.date == iterator.toISODate()) &&
              today.diff(iterator, "days").toObject().days <= 0
            )
              // data.future
              newDates.push(iterator.toISODate());
            iterator = iterator.plus({ days: 1 });
          }
          break;
        case 3: //Every week
          var daysOfWeekSelected = [];
          if (order.repeatMon) daysOfWeekSelected.push(1);
          if (order.repeatTue) daysOfWeekSelected.push(2);
          if (order.repeatWed) daysOfWeekSelected.push(3);
          if (order.repeatThu) daysOfWeekSelected.push(4);
          if (order.repeatFri) daysOfWeekSelected.push(5);
          if (order.repeatSat) daysOfWeekSelected.push(6);
          if (order.repeatSun) daysOfWeekSelected.push(7);

          if (daysOfWeekSelected.length > 0) {
            while (iterator.diff(endDate, "days").toObject().days <= 0) {
              if (
                !actualDates.some(
                  (item) => item.date == iterator.toISODate()
                ) &&
                today.diff(iterator, "days").toObject().days <= 0 &&
                daysOfWeekSelected.includes(iterator.weekday)
              )
                // data.future
                newDates.push(iterator.toISODate());
              iterator = iterator.plus({ days: 1 });
            }
          }
          break;
        // case 4: //Every month
        //   while (iterator.diff(endDate, "days").toObject().days <= 0) {
        //     if (
        //       !actualDates.includes(iterator.toISODate()) &&
        //       today.diff(iterator, "days").toObject().days <= 0
        //     )
        //       data.future.push(iterator.toISODate());
        //     iterator = iterator.plus({ months: 1 });
        //   }
        //   break;
      }
      return newDates;
    },
    timeStringToDateTime(timeString) {
      var cFormat = "{y}-{m}-{d}";
      var vals = timeString.split("-");
      var formatSplit = cFormat.split("-");
      var m = formatSplit.indexOf("{m}");
      var d = formatSplit.indexOf("{d}");
      var y = formatSplit.indexOf("{y}");
      // console.log("month", vals[m]);
      // console.log("day", vals[d]);
      // console.log("year", vals[y]);

      return DateTime.local(
        parseInt(vals[y]),
        parseInt(vals[m]),
        parseInt(vals[d]),
        0,
        0,
        0
      );
    }
  },
};
</script>
<style lang="scss" scoped>
.order-expanded-table {
}
</style>
